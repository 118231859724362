<template>
	<div>
		<!-- Page Header -->
		<div class="d-flex justify-space-between align-center mb-3">
			<h3 class="text-h4 font-weight-light">Company Revenue</h3>
			
			<div>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey"><v-icon>mdi-filter-outline</v-icon></v-btn>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey"><v-icon>mdi-cached</v-icon></v-btn>
			</div>
		</div>
		
		<!-- Page Body -->
		<div>
			<v-card>
				<v-card-text>
					<line-chart chart-id="company-revenue-bar-chart" dataset-id-key="company-revenue-label" :labels="labels" :datasets="datasets" />
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>

<script>
	import LineChart from '@/components/graphs/Line.vue'
	
	export default {
		components: {
			LineChart
		},
		data: () => ({
			labels: [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec'
			],
			datasets: [
			{
				label: 'Revenue in USD',
				backgroundColor: '#2a9c64',
				data: [423, 446, 675, 510, 590, 610, 760, 800, 450, 500, 350, 368]
			}
			]
		}),
	}
</script>
